<template>
  <TransitionRoot as="template" :show="opened">
    <Dialog as="div" class="relative z-10" @close="cancel">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <!-- <div class="text-red-600 text-yellow-600 text-green-600 bg-red-100 bg-yellow-100 bg-green-100"></div> -->
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div class="sm:flex sm:items-start">
                <div :class="`bg-${data.color}-100`" class="mx-auto flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full sm:mx-0">
                  <WarningIcon v-if="data.type == 'danger'" class="w-5 mb-1" :class="`text-${data.color}-600`" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 v-show="data.title" class="text-xs font-medium my-3">{{ data.title }}</h3>
                  <div v-show="data.message" class="text-xs mt-1" v-html="data.message"></div>
                </div>
              </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:justify-end">
                <button class="btn btn--outline" @click="cancel">{{ data.cancelBtn }}</button>
                <button :class="`btn--${data.color}`" class="btn ml-4" @click="confirm">
                  {{ data.btn }}
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import WarningIcon from '@/icons/warning.isvg'

export default {
  components: {
    Dialog, DialogPanel, TransitionChild, TransitionRoot, 
    WarningIcon
  },
  setup() {
    const opened = ref(false)
    const initData = {
      title: '',
      message: '',        // HTML / Text
      type: 'danger',     // danger, neutral
      color: 'red',       // red, yellow, green, black
      btn: 'Confirm',
      cancelBtn: 'Cancel',
      callback: null,
      cancelCallback: null,
    }
    const data = ref(initData)

    return {
      opened,
      data
    }
  },
  mounted() {
    this.$mitt.on('prompt', (data) => {
      Object.assign(this.data, data)
      this.opened = true
    })
  },
  methods: {
    confirm() {
      this.data.callback?.()
      this.hide()
    },
    cancel() {
      this.data.cancelCallback?.()
      this.hide()
    },
    hide() {
      this.opened = false
      Object.assign(this.data, this.initData)
    }
  }
}
</script>