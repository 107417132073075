<template>
  <div class="select relative inb-block border border-neutral-400"
    :class="{'z-4': opened}"
    v-click-outside="close">
    <div class="select-button"
      @click="toggle">
      <span v-elipsis class="whitespace-nowrap overflow-hidden text-ellipsis">{{ localPlaceholder }}</span>
      <ChevronDownIcon class="w-4 flex-shrink-0"
        :class="{'rotate-180': opened}" />
    </div>
    <ul ref="list" 
      v-show="opened"
      :style="style"
      class="select-variants"
      :class="{'disable-first': disableFirst}">
      <li v-for="(title, value) in localOptions" :key="value"
        :class="{active: isActive(value), disabled: disabled?.includes(value)}"
        @click="select(value)">
        {{ title }}
      </li>
    </ul>
  </div>
</template>

<script>
import ChevronDownIcon from '@/icons/chevron-down.isvg'

import { ref, computed, nextTick } from 'vue'

export default {
  components: {
    ChevronDownIcon
  },
  props: ['modelValue', 'options', 'multiple', 'placeholder', 'disableFirst', 'disabled'],
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const opened = ref(false)
    const style = ref({ top: '100%' })
    const localValue = computed({
      get() { return props.modelValue },
      set(val) { emit('update:modelValue', val) }
    })
    const localOptions = computed(() => {
      if (props.options.length) {
        return Object.fromEntries(props.options.map((o) => [o, o]))
      } else {
        return props.options
      }
    })
    const localPlaceholder = computed(() => {
      const result = props.multiple 
        ? localValue.value.map((v) => localOptions.value[v]).join(', ') 
        : localOptions.value[localValue.value]

      return result || props.placeholder || 'Select'
    })

    return {
      opened,
      style,
      localValue,
      localOptions,
      localPlaceholder
    }
  },
  methods: {
    toggle() {
      this.opened = !this.opened
      nextTick(() => {
        const bcr = this.$refs.list.getBoundingClientRect()
        if (bcr.bottom > window.innerHeight) {
          this.style = { bottom: '100%' }
        } else {
          this.style = { top: '100%' }
        }
      })
    },
    close() {
      this.opened = false
    },
    select(val) {
      if (this.disabled?.includes(val))
        return

      if (this.multiple) {
        if (window.pressedKey == 'Shift') {
          const options = Object.keys(this.localOptions)
          let min = -1
          let current = -1
          let max = -1

          options.forEach((o, i) => {
            if (this.localValue.includes(o)) {
              if (min === -1) min = i
              if (i > max) max = i
            }
            if (o == val) current = i
          })
          
          if (current > max) 
            max = current
          else if (current >= min)
            max = current
          else
            min = current

          this.localValue = options.slice(min, max + 1)
        } else if (window.pressedKey == 'Meta') {
          const exists = this.localValue.indexOf(val)
          if (exists == -1) {
            this.localValue.push(val)
          } else {
            this.localValue.splice(exists, 1)
          }
        } else {
          this.localValue = [val]
        }
      } else {
        this.localValue = val
        this.opened = false
      }
    },
    isActive(val) {
      return this.multiple ? this.localValue.includes(val) : this.localValue == val
    }
  }
}
</script>

<style scoped>
.select {
  line-height: 24px;
  max-width: 370px;
}
.select.max-w-none {
  max-width: none;
}
.select-button {
  @apply flex items-center justify-between gap-1 px-1 bg-white cursor-pointer max-w-full;
}
.select-variants {
  @apply absolute -left-px bg-white border border-neutral-400;
  min-width: calc(100% + 2px);
  width: max-content;
}
.select-variants li {
  @apply px-1 cursor-pointer;
  user-select: none;
  line-height: 2
}
.select-variants li.active {
  @apply bg-neutral-100;
}
.select-variants li:hover {
  @apply bg-neutral-100
}
.select-variants li.disabled {
  @apply opacity-50;
}
</style>