<template>
  <div 
    :class="{ 'show': showed }"
    class="alert popup">
    <div :class="{'bg-green-100': data.type == 'success', 'bg-red-100': data.type == 'danger'}" 
      class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full">
      <CheckIcon v-if="data.type == 'success'" class="w-5 text-green-600" aria-hidden="true" />
      <WarningIcon v-if="data.type == 'danger'" class="w-5 text-red-600 mb-2px" aria-hidden="true" />
    </div>
    <div class="">
      {{ data.message }}
    </div>
    <RoundButton
      v-show="data.manual"
      icon="close"
      i-class="w-4"
      class="p-1"
      @click="showed = false" />
  </div>
</template>

<script>
import CheckIcon from '@/icons/check.isvg'
import WarningIcon from '@/icons/warning.isvg'
import RoundButton from '@/components/RoundButton'

import { ref } from 'vue'

export default {
  components: {
    CheckIcon,
    WarningIcon,
    RoundButton
  },
  setup() {
    const showed = ref(false)
    const data = ref({
      message: '',
      type: '',               // success, warning, danger
      manual: false,
      delay: 3000
    })

    return {
      showed,
      data
    }
  },
  mounted() {
    this.$mitt.on('alert', this.show)
  },
  methods: {
    show(data) {
      this.data.manual = false
      Object.assign(this.data, data)
      this.showed = true
      if (!this.data.manual) {
        setTimeout(() => this.showed = false, this.data.delay)
      }
    }
  }
}
</script>

<style scoped>
.alert {
  @apply p-4 fixed left-1/2 top-4 z-10 flex items-center gap-2;
  opacity: 0;
  transform: translate(-50%, -100%);
  transition: all .3s;
}
.show {
  opacity: 1;
  transform: translate(-50%, 0);
}
</style>