import { createApp, provide, h } from 'vue'
import { vOnClickOutside } from '@vueuse/components'
import mitt from 'mitt';
import { createPinia } from 'pinia'
import { DefaultApolloClient } from '@vue/apollo-composable'

import App from '@/App.vue'
import router from "@/router"
import { apolloClientMain } from '@/appolo.js'

import 'lazysizes'

import './main.css'

window.addEventListener('keydown', (e) => window.pressedKey = e.key)
window.addEventListener('keyup', () => window.pressedKey = false)

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClientMain)
  },
  render() {
    return h(App);
  }
})

// CUSTOM DIRECTIVES
app.directive('click-outside', vOnClickOutside)

app.directive('lazyload', (el, binding) => {
  if (el.dataset.src != binding.value) {
    el.dataset.src = binding.value
    el.classList.add('lazyload')
  }
})

app.directive('elipsis', (el, binding) => {
  el.addEventListener('mouseenter', enter)
  
  function enter(e) {
    if (binding.value|| e.target.offsetWidth < e.target.scrollWidth) {
      let plate = document.querySelector('.elipsis-tooltip')
      if (!plate) {
        plate = document.createElement('span')
        plate.classList.add('elipsis-tooltip')
        document.body.appendChild(plate)
      }
      plate.innerHTML = binding.value || el.innerHTML
      plate.classList.remove('hidden')
      el.addEventListener('mousemove', move)
      el.addEventListener('mouseleave', leave)
    }
  }
  function move(e) {
    const plate = document.querySelector('.elipsis-tooltip')
    plate.style.left = e.pageX + 'px'
    plate.style.top = e.pageY + 'px'
  }
  function leave() {
    document.querySelector('.elipsis-tooltip').classList.add('hidden')
    el.removeEventListener('mousemove', move)
    el.removeEventListener('mouseleave', leave)
  }
})

function checkScroll(el) {
  const header = document.querySelector('header')
  const thead = document.querySelector('thead tr')
  const footer = document.querySelector('footer')

  header?.classList.remove('shaded')
  thead?.classList.remove('shaded')
  footer?.classList.remove('shaded')

  if (el.scrollTop != 0) {
    (thead || header).classList.add('shaded')
  }

  if (el.scrollHeight > el.clientHeight + el.scrollTop) {
    footer.classList.add('shaded')
  }
}
app.directive('scroll-shade', {
  mounted(el) { 
    el.addEventListener('scroll', () => checkScroll(el))
    setTimeout(() => checkScroll(el), 100)
  },
  updated(el) {
    setTimeout(() => checkScroll(el), 100)
  },
})

const pinia = createPinia()
const emitter = mitt()

app.config.globalProperties.$mitt = emitter
app.use(router)
   .use(pinia)
   .mount("#app")
