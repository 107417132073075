<template>
  <TransitionRoot :show="opened" 
    class="w-full h-screen fixed left-0 top-0 z-5 flex items-center">
    
    <TransitionChild 
      enter="ease-out duration-300" 
      enter-from="opacity-0" 
      enter-to="opacity-100" 
      leave="ease-in duration-200" 
      leave-from="opacity-100" 
      leave-to="opacity-0"
      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      @click="close" />

    <div class="w-full max-h-full py-8 overflow-auto">
      <TransitionChild 
        enter="ease-out duration-300" 
        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" 
        enter-to="opacity-100 translate-y-0 sm:scale-100" 
        leave="ease-in duration-200" 
        leave-from="opacity-100 translate-y-0 sm:scale-100" 
        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" 
        class="w-128 p-6 mx-auto bg-white relative flex-shrink-0">

        <h2 class="text-xs font-medium">{{ title }}</h2>
        <a class="absolute right-6 top-6" role="button" 
          @click="close" >
          <CloseIcon class="w-5" />
        </a>
        
        <OtpForm v-if="showOtpForm"
          :enable="user.otpEnabled"
          class="mt-4"
          @onSuccess="success" />

        <form v-else class="space-y-9px mt-24" autocomplete="off">
          <input autocomplete="false" name="hidden" type="text" class="hidden">
          
          <div :class="{error: v$.user.email.$errors.length}" class="input-wrap">
            <label class="block text-xs">E-mail</label>
            <div>
              <input v-model="user.email" 
                type="email"
                class="input input--lg border-neutral-600"
                :readonly="isMe"
                autocomplete="one-time-code" />
              <div class="input-errors">
                <div v-for="error of v$.user.email.$errors" :key="error.$uid">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div :class="{error: v$.user.firstname.$errors.length}" class="input-wrap">
            <label class="block text-xs">First name</label>
            <div>
              <input v-model="user.firstname" 
                type="text" 
                class="input input--lg border-neutral-600"
                autocomplete="one-time-code" />
              <div class="input-errors">
                <div v-for="error of v$.user.firstname.$errors" :key="error.$uid">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div :class="{error: v$.user.lastname.$errors.length}" class="input-wrap">
            <label class="block text-xs">Last name</label>
            <div>
              <input v-model="user.lastname" 
                type="text" 
                class="input input--lg border-neutral-600"
                autocomplete="one-time-code" />
              <div class="input-errors">
                <div v-for="error of v$.user.lastname.$errors" :key="error.$uid">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div :class="{error: v$.user.organization.$errors.length}" class="input-wrap">
            <label class="block text-xs">Organization</label>
            <div>
              <input v-model="user.organization" 
                type="text" 
                class="input input--lg border-neutral-600"
                autocomplete="one-time-code" />
              <div class="input-errors">
                <div v-for="error of v$.user.organization.$errors" :key="error.$uid">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div :class="{error: v$.user.phone.$errors.length}" class="input-wrap">
            <label class="block text-xs">Phone number</label>
            <div>
              <input v-model="user.phone" 
                type="text" 
                class="input input--lg border-neutral-600"
                autocomplete="one-time-code" />
              <div class="input-errors">
                <div v-for="error of v$.user.phone.$errors" :key="error.$uid">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div v-if="isNew" :class="{error: v$.user.role.$errors.length}" class="input-wrap pb-10">
            <label class="block text-xs">Role</label>
            <div>
              <Select 
                v-model="user.role"
                :options="userSchema.role.options"
                class="w-full max-w-none input input--lg leading-4 border-l-0 border-r-0 border-t-0 border-neutral-600" />
              <div class="input-errors">
                <div v-for="error of v$.user.role.$errors" :key="error.$uid">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <template v-else>
            <div :class="{error: v$.user.password.$errors.length}" class="input-wrap">
              <label class="block text-xs">New Password</label>
              <div>
                <input v-model="user.password" 
                  :type="showPass ? 'text' : 'password'" 
                  class="input input--lg border-neutral-600"
                  autocomplete="one-time-code" />
                <div class="input-errors">
                  <div v-for="error of v$.user.password.$errors" :key="error.$uid">{{ error.$message }}</div>
                </div>
              </div>
            </div>

            <div class="flex items-center pt-3">
              <input v-model="showPass" 
                id="show-pass" 
                type="checkbox" 
                class="h-4 w-4 mt-1 border-neutral-400" />
              <label for="show-pass" class="ml-1 block">Show Password</label>
            </div>

            <div v-if="isMe" class="flex items-center pt-3">
              <input id="otpEnabled" 
                :checked="user.otpEnabled"
                type="checkbox" 
                class="h-4 w-4 mt-1 border-neutral-400" 
                @change="otpChange" />
              <label for="otpEnabled" class="ml-1 block">Enable 2FA</label>
            </div>
          </template>

          <div class="pt-3">
            <button :disabled="sent" 
              class="btn btn--outline w-full"
              @click.prevent="onSubmit">
              {{ isNew ? 'Create' : 'Save' }}
            </button>
          </div>
        </form>

        <div class="mt-11">
          <a class="link" href="mailto:archive@avicii.com" target="_blank">Support & Contact</a>
        </div>
      </TransitionChild>
    </div>
  </TransitionRoot>
</template>

<script>
import { TransitionChild, TransitionRoot } from '@headlessui/vue'
import CloseIcon from '@/icons/close.isvg'
import Select from '@/components/Fields/Select'
import OtpForm from './OtpForm'

import { ref, computed } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf, email } from '@vuelidate/validators'
import { useMutation } from '@vue/apollo-composable'
import UPDATE_USER from '@/graphql/user-update.gql'
import userSchema from '@/schema/userSchema.json'

export default {
  components: {
    TransitionChild, TransitionRoot,
    CloseIcon,
    Select,
    OtpForm
  },
  props: ['isNew', 'successMessage', 'isMe'],
  emits: ['close', 'save'],
  setup(props) {
    const opened = ref(false)
    const v$ = useVuelidate()
    const errors = ref([])

    const user = ref({})
    const { mutate: Update } = useMutation(UPDATE_USER)

    const sent = ref(false)
    const showPass = ref(false)
    const showOtpForm = ref(false)

    const title = computed(() => (
      showOtpForm.value
        ? 'Two-factor Authentication'
        : props.isNew
          ? 'New user' 
          : `User ${user.value.id}`
    ))

    return {
      opened,
      v$,
      errors,
      userSchema,
      user,
      Update,
      sent,
      showPass,
      showOtpForm,
      title
    }
  },
  validations() {
    return {
      user: {
        email: { required, email },
        firstname: { },
        lastname: { },
        organization: { },
        phone: { },
        role: { ifNew: requiredIf(this.isNew) },
        password: { }
      }
    }
  },
  mounted() {
  },
  methods: {
    open(data, showOtp = false) {
      Object.assign(this.user, data)
      this.showPass = false
      this.user.password = ''
      this.v$.$reset()
      this.opened = true
      
      if (showOtp) {
        this.showOtpForm = true
        this.user.otpEnabled = true
      }
    },
    close() {
      this.opened = false
      this.showOtpForm = false
    },
    success() {
      this.sent = false
      this.showOtpForm = false
      this.$mitt.emit('alert', {
        message: this.successMessage,
        type: 'success'
      })
    },
    async onSubmit() {
      if (!await this.v$.$validate()) return

      const data = {
        id: this.user.id,
        email: this.user.email,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        organization: this.user.organization,
        phone: this.user.phone,
        role: this.user.role,
      }
      if (this.isNew) {
        data.role = this.user.role
      }
      if (this.user.password) {
        data.password = this.user.password
      }
      this.$emit('save', data)
    },
    otpChange(e) {
      this.user.otpEnabled = e.target.checked
      this.showOtpForm = true
    }
  }
}
</script>