<template>
  <form @submit.prevent="onSubmit" class="">
    <div v-show="enable">
      <div>
        Scan the QR code below using the Google authenticator app,
        then enter the code in the field below.
      </div>
      <img class="mt-10 mx-auto" :src="data.qrCode" alt="">
    </div>

    <div :class="{error: error}" class="input-wrap mt-14">
      <label class="block text-xs">Authenticator code</label>
      <div>
        <input v-model="token" 
          type="text"
          class="input input--lg border-neutral-600" />
        <div v-show="error" class="input-errors">
          Invalid code
        </div>
      </div>
    </div>
    <div class="mt-8">
      <button :disabled="token.length !== 6" 
        type="submit"
        class="btn btn--outline w-full">
        Confirm
      </button>
    </div>
    
    <div v-show="enable" class="mt-9">
      <div>Problem scanning the code? Copy the code below and enter it manually into the Google authenticator app.</div>
      <div ref="key" class="my-3 text-lg">
        {{ data.setupKey }}
      </div>
      <a @click="copy" role="button">Copy</a>
    </div>
  </form>
</template>

<script>
import { ref, watch } from 'vue'
import { useMutation } from '@vue/apollo-composable'
import SETUP_OTP from '@/graphql/user-otp-setup.gql'
import TOGGLE_OTP from '@/graphql/user-otp-toggle.gql'

export default {
  emits: ['onSuccess'],
  props: ['enable'],
  setup() {
    const data = ref({})
    const token = ref('')
    const error = ref(false)
    const { mutate: OtpSetup } = useMutation(SETUP_OTP)
    const { mutate: OtpToggle } = useMutation(TOGGLE_OTP)

    watch(token, () => error.value = false)

    return {
      data,
      token,
      error,
      OtpSetup,
      OtpToggle
    }
  },
  mounted() {
    if (this.enable) {
      this.OtpSetup()
        .then(({ data }) => this.data = data.otpSetup)
        .catch((error) => console.log(error))
    }
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.$refs.key.textContent)
      this.$mitt.emit('alert', {
        message: 'Copied',
        type: 'success',
        delay: 1500
      })
    },
    onSubmit() {
      this.OtpToggle({ token: this.token, enable: this.enable })
        .then(() => this.$emit('onSuccess'))
        .catch(() => this.error = true)
    }
  }
}
</script>