<template>
  <div v-if="show" class="fixed z-10 border border-white popup cursor-move"
    :style="style">
    <div v-if="asset.fileType == 'AUDIO'" 
      class="h-20 aspect-rect flex items-center justify-center cursor-pointer text-white bg-black">
      <MusicIcon class="w-4" />
    </div>
    <img 
      v-else-if="asset.previews?.thumbnail?.url"
      v-lazyload="asset.previews.thumbnail.url" 
      class="object-cover h-20 block"
      alt="">
    <div v-else 
      :class="size" 
      class="aspect-rect bg-black flex items-center justify-center text-white">
      {{ asset.format }}
    </div>
  </div>
</template>

<script>
import MusicIcon from '@/icons/music.isvg'

import { ref } from 'vue'
import { useMutation } from '@vue/apollo-composable'
import ATTACH_COLLECTIONS from '@/graphql/collections-attach.gql'

export default {
  components: {
    MusicIcon
  },
  setup() {
    const show = ref(false)
    const style = ref({})
    const asset = ref({})
    const fromCollection = ref(0)

    const { mutate: Attach } = useMutation(ATTACH_COLLECTIONS)

    return {
      show,
      style,
      asset,
      fromCollection,
      Attach
    }
  },
  mounted() {
    this.$mitt.on('assetDragStart', this.dragStart)
    this.$mitt.on('assetDragEnd', this.dragEnd)
    window.addEventListener('mouseup', this.drop)
  },
  methods: {
    dragStart({ asset, collectionId }) {
      document.body.classList.add('dragging')
      window.addEventListener('mousemove', this.drag)
      this.asset = asset
      this.fromCollection = collectionId
      this.show = true
    },
    drag(e) {
      this.style.left = e.pageX + 10 + 'px'
      this.style.top = e.pageY + 10 + 'px'
    },
    dragEnd({ toCollection, copy }) {
      if (!this.asset?.id) return

      const input = {
        assetIds: this.asset.id,
        addCollectionIds: [toCollection],
      }
      if (!copy && this.fromCollection) {
        input.removeCollectionIds = [this.fromCollection]
      }
      this.Attach({ input: input })
        .then(({ data }) => {
          if (!data.updateAssetCollections.addedCollectionIds.length) {
            this.$mitt.emit('alert', {
              message: 'Already in collection',
              type: 'success'
            })
          }
        })
        .catch((error) => console.log(error))
    },
    drop() {
      document.body.classList.remove('dragging')
      this.show = false
      this.asset = {}
      this.collectionId = 0
      window.removeEventListener('mousemove', this.drag)
    }
  }
}
</script>