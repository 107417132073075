import { createRouter, createWebHistory } from "vue-router"
import { isLoggedIn, userCan } from '@/helpers'

const defaultTitle = 'Archive'

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "Login" */'@/views/Guest/Login'),
    meta: { guest: true, title: 'Login – Archive' }
  },
  {
    path: '/assets/:id*',
    name: 'assets',
    alias: '/assets/asset/:id*',
    component: () => import(/* webpackChunkName: "Assets" */'@/views/Auth/Assets'),
    meta: { auth: true, title: 'Assets – Archive' }
  },
  // {
  //   path: '/asset/:id',
  //   name: 'asset',
  //   component: () => import(/* webpackChunkName: "Asset" */'@/views/Auth/Asset'),
  //   meta: { auth: true, title: 'Asset :id – Archive' }
  // },
  {
    path: '/collections/:id*',
    name: 'collections',
    alias: '/collections/asset/:id*',
    component: () => import(/* webpackChunkName: "Collections" */'@/views/Auth/Collections'),
    meta: { auth: true, title: 'Collections - Archive' } 
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "ForgotPassword" */'@/views/Guest/ForgotPassword'),
    meta: { guest: true, title: 'Forgot password – Archive' }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "ResetPassword" */'@/views/Guest/ResetPassword'),
    meta: { guest: true, title: 'Reset password – Archive' }
  },
  {
    path: '/usage/:id*',
    name: 'usage',
    alias: '/usage/asset/:id*',
    component: () => import(/* webpackChunkName: "Accesses" */'@/views/Auth/Accesses'),
    meta: { auth: true, title: 'Usage – Archive' }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "Users" */'@/views/Auth/Users'),
    meta: { auth: true, title: 'Assets – Users', rights: 'viewUser' }
  },
  {
    path: '/404',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "404" */'@/views/404'),
    meta: { title: '404 – Archive' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.auth && !isLoggedIn()) {
    next({ name: 'login' })
    return
  } 

  if (to.meta.guest && isLoggedIn()) {
    next({ name: 'assets' })
    return
  }

  if (to.name == 'asset') {
    document.title = to.meta.title.replace(':id', to.params.id)
  } else if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = defaultTitle
  }

  if (to.meta.rights && !userCan(to.meta.rights)) {
    next({ name: 'login' })
    return
  }
  next()
})

router.afterEach(() => {
  // document.querySelectorAll('[scrollable]').forEach((item) => {
  //   item.scrollTo(0, 0)
  // })
})

export default router